class MobileCollapse {
  private trigger: HTMLElement | null;
  private content: HTMLElement | null;
  private is_shown: boolean;
  public constructor(trigger: string, content: string) {
    this.trigger = window.document.querySelector<HTMLElement>(trigger);
    this.content = window.document.querySelector<HTMLElement>(content);

    this.is_shown = false;

    this._attachEventListeners();
  }

  private _attachEventListeners() {
    if (!this.trigger) {
      return;
    }

    this.trigger.addEventListener("click", e => {
      e && e.preventDefault();
      this.toggle();
    });
  }

  public toggle() {
    if (!this.trigger || !this.content) {
      return;
    }

    if (this.is_shown) {
      this.is_shown = false;
      this.content.classList.remove("open");
      this.trigger.classList.remove("open");
    } else {
      this.is_shown = true;
      this.content.classList.add("open");
      this.trigger.classList.add("open");
    }
  }
}

export default MobileCollapse;
