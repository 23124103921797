import "@app/modules/core/touch-device";
import "@app/polyfills/dialog-polyfill";

import { setupWhitelabelTheme } from "@app/entries/setups/whitelabel-theme";

import CoreUI from "../modules/core/core-ui";
import UI from "../modules/core/ui";
import HeaderDropdowns from "../modules/core/vanilla/header-dropdowns";
import MobileFooter from "../modules/footer/mobile-footer-client";

(function preloadTheme() {
  void setupWhitelabelTheme();
})();

window.addEventListener("load", () => {
  const ui = UI.init();
  CoreUI.init(ui);
  MobileFooter.init();
  HeaderDropdowns();
});
