import enquire from "enquire.js";
import $ from "jquery";

export class UI {
  public static init() {
    return new UI();
  }

  private mq: string = "small";
  private $body: JQuery<HTMLElement>;
  private $main: JQuery<Element> | null;
  private media_queries: Record<
    string,
    {
      rule: string;
      breakpoint: number;
      active: boolean;
    }
  > = {};
  private $active_view: JQuery<HTMLElement> | null = null;
  private $open_dropdown: JQuery<HTMLElement> | null = null;

  public constructor() {
    this.$body = $("body");
    this.$main = $("#main-view");

    this._initializeMediaQueryEvents();
    this._initializeViews();
  }

  ///////////////////////////////
  // Media queries and media query change events
  ///////////////////////////////
  private _initializeMediaQueryEvents(): void {
    const foundation_mq_regex = /^[/\\'"]+|(;\s?})+|[/\\'"]+$/g;
    const $head = $("head");

    // keep track of our media queries and which ones are active
    // we are simpliying things a bit and only using the $[size]-up queries
    ["small", "medium", "large"].forEach(mq_name => {
      // append meta tag -- styles set by foundation to include mq settings
      const $meta = $("<meta>").addClass("foundation-mq-" + mq_name);
      $head.append($meta);
      this.media_queries[mq_name] = {
        rule: $meta.css("font-family").replace(foundation_mq_regex, ""),
        breakpoint: parseInt($meta.css("width")),
        active: false
      };
      enquire.register(this.media_queries[mq_name].rule, {
        match: () => {
          this.media_queries[mq_name].active = true;
          this._updateActiveMq();
        },
        unmatch: () => {
          this.media_queries[mq_name].active = false;
          this._updateActiveMq();
        }
      });
    });
  }

  private _updateActiveMq(): void {
    const last_mq = this.mq;
    Object.keys(this.media_queries).forEach(name => {
      const mq = this.media_queries[name];
      if (mq.active) {
        this.mq = name;
      }
    });
    // trigger some listenable events for the change
    if (last_mq !== this.mq) {
      this.$body.trigger("mediaquerychange");
      this.$body.trigger("mediaquerychange:from:" + last_mq);
      this.$body.trigger("mediaquerychange:to:" + this.mq);
    }
  }

  ///////////////////////////////
  // Fullscreen Views
  ///////////////////////////////
  private _initializeViews(): void {
    $("body").on("mediaquerychange:from:small", () => this.hideActiveView());
  }

  public showView($view: JQuery<HTMLElement>): void {
    this.$active_view = $view;

    // just to make sure its within the view container
    // this applies if the view is dynamically created
    this.$body.append($view);

    // views start out hidden, then are repositioned offscreen
    // this shows it and adds is-visible which brings it onscreen
    $view.show();
    setTimeout(() => {
      $view.addClass("is-visible");
      this.$main?.addClass("is-locked");
    }, 0);

    // rebind close button
    $view.find(".view-close-button").one("click", () => this.hideActiveView());

    // TODO: figure out reusable way to do first-focus within the view
    $view.one(
      "transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd",
      () => {
        $("#from").focus();
      }
    );
  }

  public hideActiveView(): void {
    if (!this.$active_view) {
      return;
    }
    const $hiding_view = this.$active_view;
    $hiding_view.removeClass("is-visible");

    setTimeout(() => {
      $hiding_view.hide();
    }, 500);
  }

  public closeActiveDropdown(): void {
    if (!this.$open_dropdown) {
      return;
    }
    this.$open_dropdown.removeClass("open");
    this.$open_dropdown = null;
    $("body").off("click", () => this.closeActiveDropdown());
  }
}

export default UI;
