import MobileCollapse from "../../components/mobile-collapse/mobile-collapse-client";

const MobileFooter = {
  init() {
    this.account_collapse = new MobileCollapse(
      "#account-collapse",
      "#account-links"
    );
    this.coverage_collapse = new MobileCollapse(
      "#coverage-collapse",
      "#coverage-links"
    );
    this.company_collapse = new MobileCollapse(
      "#company-collapse",
      "#company-links"
    );
  }
};

export default MobileFooter;
