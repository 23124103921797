import { config as ahumada } from "./ahumada/definition";
import { config as billetesbus } from "./billetesbus/definition";
import { config as checkmybus } from "./checkmybus/definition";
import { config as flighthub } from "./flighthub/definition";
import { config as justfly } from "./justfly/definition";
import { config as neredennereye } from "./neredennereye/definition";
import { config as recorrido } from "./recorrido/definition";
import { config as skyscanner } from "./skyscanner/definition";
import { config as virail } from "./virail/definition";
import { config as walmart } from "./walmart/definition";

export const PARTNERS = {
  neredennereye,
  skyscanner,
  billetesbus,
  recorrido,
  virail,
  flighthub,
  justfly,
  checkmybus,
  walmart,
  ahumada
} as const;
