import $ from "jquery";

import UI from "./ui";

const CoreUI = {
  init: function (ui: UI) {
    $(document).on("click", 'a[href="#"]', function (e) {
      e.preventDefault();
    });

    // menu currency/language init
    $(".js-menu-currency").click(function () {
      ui.showView($("#change-currency-view"));
    });

    $("#change-currency-view .list-link").click(function () {
      window.location.href = $(this).data("url");
    });

    $(".js-menu-language").click(function () {
      ui.showView($("#change-language-view"));
    });

    //prevents reseting to default date when language or currency is changed
    $("#switch-language-links > li > a, #switch-currency-links > li > a").click(
      function () {
        var $self = $(this);
        var url = $self.attr("href") + window.location.hash;
        $self.attr("href", url);
      }
    );
  }
};

export default CoreUI;
